<template>
  <v-container>
    <v-snackbar
      v-model="permSnackbar"
      :color="snackbarColor"
      :timeout="timeout"
    >
      {{ errorText }}
      <template v-slot:action="{ attrs }">
        <v-btn icon text v-bind="attrs" @click="permSnackbar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-card elevation="12" min-height="600">
      <v-row>
        <v-col cols="6">
          <v-card-title>I miei housing ({{ devices.length ? devices.length:"loading" }})</v-card-title>
        </v-col>
        
      </v-row>

      <v-card-text>
        <v-row v-show="loading" justify="center">
          <v-col cols="2">
            <br />
            <br />
            <br />
            <v-progress-circular
              color="primary"
              indeterminate
              :size="70"
              :width="7"
            />
            <br />
            <br />
            <br />
          </v-col>
        </v-row>
        <v-data-table
          v-show="!loading"
          elevation="10"
          :headers="headers"
          :items=devices
          item-key="Name"
          :expanded.sync="expanded"
          show-expand
          @click:row="clickRow"
          :search="search"
          :footer-props="{
            showFirstLastPage: true,
            firstIcon: 'mdi-arrow-collapse-left',
            lastIcon: 'mdi-arrow-collapse-right',
            prevIcon: 'mdi-minus',
            nextIcon: 'mdi-plus',
            itemsPerPageText: 'Apparati per pagina',
            itemsPerPageAllText: 'Tutti gli apparati',
            itemsPerPageOptions: [10, 25, 50, -1],
            pageText: '',
          }"
        >
          <template v-slot:top>
            <v-text-field
              v-model="search"
              label="Filtra..."
              class="mx-4"
              
              itemsPerPageText="'Apparati per pagina'"
              clearable
            />
          </template>
          <template v-slot:no-data>
            <v-sheet> Nessun Apparato </v-sheet>
          </template>
          <template v-slot:no-results>
            <v-sheet> Nessun Apparato </v-sheet>
          </template>

        <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          Rack: {{ item.Rack }} <br>
          Modello: {{ item.Model }} <br>
          Produttore: {{ item.Manufacturer }}
        </td>
      </template>
         
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>

export default {
  name: "MyHousings",
  data: () => {
    return {

      devices: [],
      search: "",
      expanded: [],
      loading: false,
      permSnackbar: false,
      snackbarColor: "success",
      errorText: "",
      timeout: -1,
     
    };
  },
  computed: {
    headers() {
      return [
        {
          text: "Nome Apparato",
          align: "start",
          sortable: true,
          value: "Name",
         },
        {
          text: "Descrizione",
          align: "start",
          sortable: false,
          value: "Description",
        },
        {
          text: "Competenza",
          align: "start",
          sortable: false,
          value: "Tenant",
         },
      //   {
      //     text: "Responsabile",
      //     align: "end",
      //     sortable: true,
      //     value: "Owner",
      //   },
      //   {
      //     text: "Fine Servizio",
      //     align: "end",
      //     sortable: true,
      //     value: "EndService",
      //   },
      //   {
      //     text: "Stato",
      //     align: "center",
      //     sortable: true,
      //     value: "Status",
      //   },
      ];
    },
    // ...mapState(["userInitials", "userRole", "userPrivacy"]),
  },

  created: function () {
    this.loading = true;
    this.loadMyDevices();
  },
  methods: {
    loadMyDevices() {
      this.loading = true;

      this.$http
        .get(process.env.VUE_APP_PATRIMONIO_API + "/housings/" + this.$store.state.userMatricola + '/mylist', {
          headers: {
            authorization: "Bearer " + this.$store.state.authJwt,
          },
        })
        .then((response) => {
          this.devices = response.data.map(item => ({
            Name: item.name,
            Tenant: item.tenant.name,
            Description: item.description,
            Rack: item.rack.name,
            Model: item.device_type.model,
            Manufacturer: item.device_type.manufacturer.name,
          }));
          
          this.loading = false;
        })
        .catch((err) => {
          this.errorText = err.response.data.message;
          this.permSnackbar = true;
          this.snackbarColor = "red";
          this.loading = false;
        });
    },

    // expand the row data in the table
    clickRow(item, event) {
      if(event.isExpanded) {
        const index = this.expanded.findIndex(i => i === item);
        this.expanded.splice(index, 1)
      } else {
        this.expanded.push(item);
      }
    }

  },
};
</script>