var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-snackbar',{attrs:{"color":_vm.snackbarColor,"timeout":_vm.timeout},scopedSlots:_vm._u([{key:"action",fn:function({ attrs }){return [_c('v-btn',_vm._b({attrs:{"icon":"","text":""},on:{"click":function($event){_vm.permSnackbar = false}}},'v-btn',attrs,false),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}]),model:{value:(_vm.permSnackbar),callback:function ($$v) {_vm.permSnackbar=$$v},expression:"permSnackbar"}},[_vm._v(" "+_vm._s(_vm.errorText)+" ")]),_c('v-card',{attrs:{"elevation":"12","min-height":"600"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-card-title',[_vm._v("I miei housing ("+_vm._s(_vm.devices.length ? _vm.devices.length:"loading")+")")])],1)],1),_c('v-card-text',[_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"2"}},[_c('br'),_c('br'),_c('br'),_c('v-progress-circular',{attrs:{"color":"primary","indeterminate":"","size":70,"width":7}}),_c('br'),_c('br'),_c('br')],1)],1),_c('v-data-table',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],attrs:{"elevation":"10","headers":_vm.headers,"items":_vm.devices,"item-key":"Name","expanded":_vm.expanded,"show-expand":"","search":_vm.search,"footer-props":{
          showFirstLastPage: true,
          firstIcon: 'mdi-arrow-collapse-left',
          lastIcon: 'mdi-arrow-collapse-right',
          prevIcon: 'mdi-minus',
          nextIcon: 'mdi-plus',
          itemsPerPageText: 'Apparati per pagina',
          itemsPerPageAllText: 'Tutti gli apparati',
          itemsPerPageOptions: [10, 25, 50, -1],
          pageText: '',
        }},on:{"update:expanded":function($event){_vm.expanded=$event},"click:row":_vm.clickRow},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Filtra...","itemsPerPageText":"'Apparati per pagina'","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"no-data",fn:function(){return [_c('v-sheet',[_vm._v(" Nessun Apparato ")])]},proxy:true},{key:"no-results",fn:function(){return [_c('v-sheet',[_vm._v(" Nessun Apparato ")])]},proxy:true},{key:"expanded-item",fn:function({ headers, item }){return [_c('td',{attrs:{"colspan":headers.length}},[_vm._v(" Rack: "+_vm._s(item.Rack)+" "),_c('br'),_vm._v(" Modello: "+_vm._s(item.Model)+" "),_c('br'),_vm._v(" Produttore: "+_vm._s(item.Manufacturer)+" ")])]}}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }